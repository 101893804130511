import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../AuthProvider';

const WhatsNewModal = ({isWhatsNewModalOpen = false, setIsWhatsNewModalOpen, user }) => {
    const navigate = useNavigate();
    const api = useApi();

    React.useEffect(() => {
        if (window.location.pathname === '/' && user !== null && user !== undefined && user?.seen_latest_features === false) {
            //TODO this doesn't seem efficient
            setIsWhatsNewModalOpen(true);
        }
    }, [window.location.pathname, user]);

    return (
        <React.Fragment>
            <Dialog
                open={isWhatsNewModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'900px'}
            >
                <DialogTitle id="alert-dialog-title">
                    <h3>{"Recent Updates"}</h3>
                </DialogTitle>
                <DialogContent className="whats-new-content">
                    <section>
                        <h3>Nov 10, 2024</h3>
                        <ul>
                            <li>
                                <strong>Improved File Export:</strong>&nbsp;Format improvements to Excel exports now make analyzing results even easier
                            </li>
                            <li>
                                <strong>Improved Question Editing:</strong>&nbsp;Now you can edit the Question Type after its been added to the survey
                            </li>
                            <li>
                                <strong>Bug Fixes:</strong>&nbsp;Remedied usability issues to improve stability, customer experience and survey output
                            </li>
                        </ul>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        if (!user?.seen_latest_features) {
                            // flip the seen_latest_features flag until the next release
                            const featuresResponse = await api.post(`/users/seen-latest-features`);
                            if (featuresResponse && featuresResponse.status !== 200) {
                                console.error('Error changing seen_latest_features');
                            }
                        }
                        setIsWhatsNewModalOpen(false);
                        }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default WhatsNewModal;