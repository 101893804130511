import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../AuthProvider';

let hasBeenAutoOpened = false;
const VideoModal = ({isVideoModalOpen = false, setIsVideoModalOpen}) => {
    const navigate = useNavigate();
    const api = useApi();

    if (window.location.pathname === "/welcome" && !hasBeenAutoOpened) {
        // auto-open if part of the login flow
        setIsVideoModalOpen(true);
        hasBeenAutoOpened = true;
    }

    return (
        <React.Fragment>
            <Dialog
                open={isVideoModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'900px'}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Welcome to Crowdwave!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <iframe width="854" height="480" src="https://www.youtube.com/embed/6ZYcU683WLc?si=JI94izjO7hlOzqOv" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        console.log('closing video modal');
                        console.log(isVideoModalOpen);
                        setIsVideoModalOpen(false);
                        navigate('/');
                        console.log(isVideoModalOpen);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default VideoModal;