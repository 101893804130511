import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-row">
            <span>&copy; 2024 Crowdwave</span>
        </div>
        <div className="footer-row">
            <Link data-test-id="footer-tnc-link" to={'/terms-and-conditions'} target="_blank">Terms and Conditions</Link>
            <Link data-test-id="footer-faq-link" to={'https://www.crowdwave.ai/faq'} target="_blank">FAQ</Link>
            <Link data-test-id="footer-contact-link" to={'mailto:contact@crowdwave.ai'}>contact@crowdwave.ai</Link>
        </div>
    </div>
  );
}

export default Footer;