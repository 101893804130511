import RightPanel from '../RightPanel';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import close from '../../assets/close.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import upload from '../../assets/upload-icon.svg';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import DynamicTable from '../DynamicTable';
import templateDoc from '../../assets/documents/Crowdwave Survey Questions Upload Template.xlsx'
import { styled } from '@mui/material/styles';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Questions = ({
  activeStep,
  questionsData,
  setQuestionsData,
  hasQuestionsData,
  handleUpload,
  newQuestionText,
  setNewQuestionText,
  hasQuestionTextError,
  handleRemoveValidationForQuestionsChange,
  newQuestionType,
  handleQuestionTypeFieldChange,
  newQuestionOptions,
  setNewQuestionOptions,
  hasQuestionOptionsError,
  addQuestion,
  addQuestionsLimit,
  validateQuestion,
  enableOptionsEditing,
  hasQuestionCountError,
  audienceData,
  hasAudienceData,
  segmentData,
  hasSegmentData,
  questionActions,
  currentContext,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const uploadInfoRows = [
    { question_text: 'Do you enjoy cooking?', question_type: 'Short Response', sort_order: 1, options: '' },
    { question_text: 'Describe a typical dinner at your house', question_type: 'Long Response', sort_order: 2, options: '' },
    { question_text: 'How would you describe your diet?', question_type: 'Single Choice', sort_order: 4, options: 'Vegan,Dietary Restrictions,Allergen Restrictions,No Restrictions' },
    { question_text: 'What types of food do you buy?', question_type: 'Multiple Choice', sort_order: 5, options: 'Fresh,Frozen,Organic,Local,Imported' },
    { question_text: 'Rate your diet from 1 (unhealthy) to 5 (very healthy)', question_type: 'Numeric Rating', sort_order: 3, options: '' },
  ];

  return (
  <Grid container>
    <Grid xs={12} md={12} lg={12} >
      <Container className="left-panel stacked-top">
        <h2>Questions</h2>
        <p>Add your questions here.</p>
        <div>
          <Grid container spacing={2}>
            <Grid xs={12} md={12} lg={5} style={{ display: isActive ? 'none' : 'block' }}>
              <Button
                style={{ top: '30%' }}
                onClick={handleToggle}
                variant="contained"
                startIcon={<CloudUploadIcon />}
                xs={12} md={12} lg={4}
                className="crowdwave-blue-light">
                Bulk Upload Questions
              </Button>
            </Grid>
            <Grid style={{ display: isActive ? 'block' : 'none' }} xs={6} md={6} lg={7}>
              <p>
                Upload your own document to the right, or download our <a href={templateDoc}>pre-built XLSX template</a> to ensure compatibility.
                The required format is shown below:
              </p>
            </Grid>
            <Grid className='upload-block' style={{ display: isActive ? 'flex' : 'none' }} xs={12} md={12} lg={5}>
              <em className='close-icon' onClick={handleToggle}> <img src={close} alt="upload" /></em>
              <Button component="label" role={undefined} variant="contained" data-test-id="upload-questions-btn">
                <em><img src={upload} alt="upload" /></em> Click to Upload XLSX
                <VisuallyHiddenInput type="file" accept=".xlsx" onChange={(e) => handleUpload(e)} />
              </Button>
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <TableContainer className="upload-info-text" component={Paper} style={{ display: isActive ? 'block' : 'none' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: '2px solid #999' }}>
                      <TableCell>Question Text</TableCell>
                      <TableCell align="center">Question Type</TableCell>
                      <TableCell align="center">Sort Order</TableCell>
                      <TableCell align="center">Options</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadInfoRows.map((row) => (
                      <TableRow
                        key={row.sort_order}
                      >
                        <TableCell>{row.question_text}</TableCell>
                        <TableCell align="center">{row.question_type}</TableCell>
                        <TableCell align="center">{row.sort_order}</TableCell>
                        <TableCell align="center">{row.options}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <Box className="textField-box">
              <Box>
                <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                  <label style={{ fontSize: '24px', color: '#161616', fontWeight: '500' }}
                    className='label'><span>Add Question</span>
                  </label>
                </Stack>
              </Box>
              <Box className='question-content' sx={{ mt: 2 }}>
                <textarea
                  data-test-id="question-text"
                  placeholder={'Please add a question'}
                  value={newQuestionText}
                  onChange={(e) => setNewQuestionText(e.target.value)}
                  className={hasQuestionTextError ? 'has-error' : ''}
                  onKeyUp={(e) => handleRemoveValidationForQuestionsChange('question_text', e)}
                >
                </textarea>
                {/* <div tabIndex={(index * 2) + 2} className="improve-btn"><em><img src={sparkles} alt="sparkles" /></em> Improve with AI </div> */}
              </Box>

              <Box className={`selectbox ${isActive ? 'active' : ''}`}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    data-test-id="question-type"
                    value={newQuestionType}
                    onChange={(e) => handleQuestionTypeFieldChange(e)}
                    displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="Short Response">Short Response</MenuItem>
                    <MenuItem value="Long Response">Long Response</MenuItem>
                    <MenuItem value="Single Choice">Single Choice</MenuItem>
                    <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
                    <MenuItem value="Numeric Rating">Numeric Rating</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {
                (newQuestionType === 'Single Choice' || newQuestionType === 'Multiple Choice')
                &&
                <Box className='question-content' style={{ width: '97.5%', mb: 2 }}>
                  <input
                    data-test-id="question-options"
                    type="text"
                    id="myQuestion"
                    value={newQuestionOptions}
                    onChange={(e) => setNewQuestionOptions(e.target.value)}
                    onKeyUp={(e) => handleRemoveValidationForQuestionsChange('options', e)}
                    className={hasQuestionOptionsError ? 'has-error' : ''}
                    placeholder={hasQuestionOptionsError ? 'Please enter comma separated options' : 'ex: Option 1,Option 2,Option 3'}
                  />
                </Box>
              }
            </Box>
            {
              questionsData.length !== addQuestionsLimit &&
              <Button
                data-test-id="add-question-btn"
                onClick={addQuestion}
                variant="contained"
                startIcon={<AddIcon />}
                className="crowdwave-blue">Add question</Button>
            }
          </Box>
        </div>
      </Container>
      <DynamicTable
        tableData={questionsData}
        visibleColumns={['question_text', 'question_type', 'raw_options']}
        columnLabels={['Question Text', 'Question Type', 'Options', 'Actions']}
        editableColumns={['question_text', 'question_type', 'raw_options']}
        smallColumns={['actions']}
        selectOnClick={false}
        selectable={false}
        draggable={true}
        updateTableData={setQuestionsData}
        actions={questionActions}
        isCellEditable={enableOptionsEditing}
        itemName="question"
        rowCountError={hasQuestionCountError}
      />
    </Grid>
    {/* <Grid xs={12} md={12} lg={4}>
      <RightPanel
        audienceData={audienceData}
        hasAudienceData={hasAudienceData}
        segmentData={segmentData}
        hasSegmentData={hasSegmentData}
        questionsData={questionsData}
        hasQuestionsData={hasQuestionsData}
        selectSteps={selectSteps} activeTab={2} />
    </Grid> */}

  </Grid>
)}

export default Questions;